import React from "react";
import DecorativeCurve from "../components/DecorativeCurve";
import Layout from "../components/Layout";
import Title from "../components/Title";

const privacyPolicy = [
  {
    title: "Introduction",
    content: `Coordinator respects the privacy of your personal information and
    is committed to protecting the personal information that you provide.
    This Privacy policy applies to all personal information Coordinator processes,
    including the collecting, publication, sharing and usage of personal
    information. The Privacy policy describes how Coordinator processes your personal
    data and how you can access and make changes to your personal data.`,
  },
  {
    title: "Personal information",
    content: `You don't need to register to explore the website. Coordinator collects
    personal information when you register with Coordinator. You will not be targeted by promotional messages
    or newsletters unless you explicitly opt in. You have the right to access your personal
    data and the right to request rectification and erasure of your personal data.`,
  },
  {
    title: "Information sharing",
    content: `Coordinator does not rent, trade, or share personal information with third parties.`,
  },
  {
    title: "Confidentiality and security",
    content: `The security of your personal information is important to us.
    When you enter sensitive information on our registration or order forms,
    we encrypt that information using secure socket layer technology (SSL).
    Passwords are encrypted before they are inserted in our database,
    and we generally strive to achieve the best security standards.`,
  },
  {
    title: "Contact form",
    content: `When contacting Coordinator through the contact form on the website,
    you are required to provide an email address for Coordinator to reply to your enquiry.
    Your email address won't be used for promotional purposes and will only be stored for
    as long as it is necessary and appropriate.`,
  },
  {
    title: "Questions or suggestions",
    content: (
      <p>
        If you have any questions, suggestions, or concerns about the issues discussed in this
        document please email us at{" "}
        <a href="mailto:coordinator@tommasoamici.com?subject=Privacy policy on coordinator.tommasoamici.com">
          coordinator@tommasoamici.com
        </a>
        .
      </p>
    ),
  },
];

const PrivacyPolicyPage = () => {
  return (
    <Layout seo={{ title: "Privacy policy" }} hasDecoration={true}>
      <article className="flex flex-col items-center justify-center mx-auto my-auto space-y-6 leading-7 max-w-prose">
        <Title>Privacy policy</Title>
        {privacyPolicy.map((p, i) => (
          <section className="w-full">
            <h2 className="text-2xl font-semibold">
              {i + 1}. {p.title}
            </h2>
            {p.content}
          </section>
        ))}
      </article>
      <DecorativeCurve />
    </Layout>
  );
};

export default PrivacyPolicyPage;
